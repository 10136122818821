import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getContracts } from 'modules/contracts/actions';
import useLiterals from 'utils/hooks/useLiterals';
import Layout from 'components/Layout';
import StepsProgress from 'components/UI/StepsProgress';
import Box from 'components/UI/Box/Box';
import Select from 'components/UI/Select';
import Button from 'components/UI/Button';
import { ReactComponent as CardIcon } from 'assets/icons/card.svg';
import { ReactComponent as BillingIcon } from 'assets/icons/money_red.svg';
import { formatDate } from 'utils/formatDate';
import { formatCurrency } from 'utils/formatCurrency';
import PaymentReference from 'components/PaymentReference';
import BBVA from 'components/BBVA';
import useOutOfOffice from 'utils/hooks/useOutOfOffice';
import PaymentOutOfHour from 'components/PaymentOutOfHour';
import { renderIconCategories } from 'components/PaymentBox';
import Translate from 'components/Translate';
import InputAmountSteps from 'components/UI/InputAmountSteps';
import { PAYCASH_MAX_AMOUNT, PROVIDER_TYPE } from 'constants/global';
import './styles.scss';

const MultiplePayment = () => {
  const literals = useLiterals('contractPay');
  const allContracts = useSelector((state) => state.contracts);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { schedule: paymentsSchedule, isOutOfHour } = useOutOfOffice();
  const [form, setForm] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [step, setStep] = useState(0);

  const contracts = useMemo(() => {
    const data = [];
    if (allContracts?.list) {
      allContracts.list.forEach((contract) => {
        if (contract.OpcionesPago.length > 0) {
          data.push({
            ...contract,
            options: contract?.OpcionesPago?.map((option, index) => ({
              ...option,
              key: `${option.ClavePago}-${index}`,
              label: `${option.DescripcionPago} ${option.Monto ? ` (${formatCurrency(option.Monto)})` : ''}`,
            })) || [],
          });
        }
      });
    }
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allContracts]);

  const { totalAmount, validAmount } = useMemo(() => (
    {
      totalAmount: parseFloat(Object.values(form).reduce((acc, curr) => acc + parseFloat(curr?.amount || 0), 0.00).toFixed(2)),
      validAmount: Object.values(form).every((c) => !c.error),
    }
  ), [form]);
  const paycashAvailable = totalAmount <= PAYCASH_MAX_AMOUNT;

  useEffect(() => {
    if (!contracts.loaded) {
      dispatch(getContracts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickStep0 = () => {
    if (!paycashAvailable && paymentMethod === PROVIDER_TYPE.PAYCASH) {
      setPaymentMethod(null);
    }
    setStep(1);
  };

  const handleSelectPayment = (option, contractId) => {
    const newValue = { ...form };
    if (!option) {
      delete newValue[contractId];
    } else {
      newValue[contractId] = {
        ...option,
        amount: option.Monto,
        error: false,
      };
    }
    setForm(newValue);
  };

  const handleChangeAmount = (value, contractId) => {
    const newValue = { ...form };
    const oldValue = newValue[contractId];

    const error = (value < oldValue?.Monto)
      || (oldValue?.PagoMinimo && value < oldValue.PagoMinimo && value !== oldValue?.Monto)
      || (oldValue?.PagoMaximo && value > oldValue.PagoMaximo);

    newValue[contractId] = {
      ...newValue[contractId],
      amount: value,
      error,
    };
    setForm(newValue);
  };

  const renderStep0 = () => {
    return (
      <>
        <div className='contracts-list'>
          {contracts.map((contract) => {
            const selected = form[contract.Numero] || null;
            return (
              <Box className='mb-3' key={contract.Numero}>
                <div className='d-flex gap-3 align-items-center mb-2'>
                  {renderIconCategories(contract.Ramo, contract.isAuto)}
                  <h3 className='mb-0'>{contract.Numero}</h3>
                </div>
                <p>
                  <b>{`${literals.office}: `}</b>
                  {contract.NombreSucursal}
                </p>
                <p>
                  <b>{`${literals.pendingCapital}: `}</b>
                  {formatCurrency(contract.CapitalRestante)}
                </p>
                <p className='mb-2 font-bold'>{literals.amortize}</p>
                {contract.seRealizoPago ? (
                  <Select
                    value='0'
                    options={[{
                      label: literals.onlyOnePayment,
                      value: '0',
                    }]}
                    onChange={() => {}}
                    disabled
                  />
                ) : (
                  <Select
                    canBeEmpty={false}
                    options={
                      [
                        {
                          label: literals.noPayment,
                          value: '0',
                        },
                        ...(contract.options.map((option) => (
                          {
                            label: option.label,
                            value: option.key,
                          }
                        )) || []),
                      ]
                    }
                    onChange={(v) => handleSelectPayment(contract.options.find((c) => c.key === v), contract.Numero)}
                    value={selected?.key || '0'}
                  />
                )}
                {selected && (
                  <>
                    <div className='mt-2 mb-1 font-bold'>{literals.limitDate}</div>
                    <div className='text-gray'>{formatDate(selected?.FechaLimitePago, 'DD/MM/YYYY')}</div>
                    <div className='mt-2 mb-1 font-bold'>{literals.amount}</div>
                    <InputAmountSteps
                      value={selected.amount}
                      originalValue={selected.Monto}
                      min={selected.PagoMinimo}
                      max={selected.PagoMaximo}
                      error={selected.error}
                      onChange={(v) => handleChangeAmount(v, contract.Numero)}
                    />
                  </>
                )}
              </Box>
            );
          })}
        </div>
        <Button
          onClick={handleClickStep0}
          text={`${literals.common.next} ${totalAmount ? `(${formatCurrency(totalAmount)})` : ''}`}
          className='mt-3'
          disabled={totalAmount === 0 || !validAmount}
        />
      </>
    );
  };

  const renderStep1 = () => {
    return (
      <>
        <h3 className='mb-3'>{literals.multiplePayment}</h3>
        <Translate
          literal={literals.multipleResume}
          vars={{ contracts: Object.keys(form).length, amount: formatCurrency(totalAmount) }}
        />
        <h3 className='mb-3'>{literals.paymentMethod}</h3>
        <div className='grid-container'>
          <Box
            className={`${paymentMethod === PROVIDER_TYPE.BANCOMER && 'selected'} payment-option-box d-flex align-items-center text-center`}
            onClick={() => setPaymentMethod(PROVIDER_TYPE.BANCOMER)}
          >
            <div className='py-2'>
              <CardIcon className='' width={35} />
              <h3 className='mt-3 font-size-sm'>{literals.card}</h3>
            </div>
          </Box>
          <Box
            className={`${paymentMethod === PROVIDER_TYPE.PAYCASH && 'selected'} payment-option-box d-flex align-items-center text-center ${paycashAvailable ? '' : 'disabled'}`}
            onClick={() => paycashAvailable && setPaymentMethod(PROVIDER_TYPE.PAYCASH)}
          >
            <div className='py-2'>
              <BillingIcon className='' width={35} />
              <h3 className='mt-3 font-size-sm'>{literals.referencePayment}</h3>
              <h3 className='mt-3 font-size-sm'>{literals.paycash}</h3>
              {!paycashAvailable && (
                <Translate
                  className='mb-0 font-size-xs'
                  literal={literals.maxAmountPaycash}
                  vars={{ amount: formatCurrency(PAYCASH_MAX_AMOUNT) }}
                />
              )}
            </div>
          </Box>
          <Box
            className={`${paymentMethod === PROVIDER_TYPE.PUNTORED && 'selected'} payment-option-box d-flex align-items-center text-center`}
            onClick={() => setPaymentMethod(PROVIDER_TYPE.PUNTORED)}
          >
            <div className='py-2'>
              <BillingIcon className='' width={35} />
              <h3 className='mt-3 font-size-sm'>{literals.referencePayment}</h3>
              <h3 className='mt-3 font-size-sm'>{literals.puntored}</h3>
            </div>
          </Box>
        </div>
        <Button
          onClick={() => setStep(2)}
          className='mt-4'
          type='primary'
          disabled={!paymentMethod}
          text={literals.pay}
        />
      </>
    );
  };

  const renderStep2 = () => {
    const orders = Object.keys(form).map((cid) => ({
      contract_id: cid,
      payment_key: form[cid].ClavePago,
      contract_amount: form[cid].Monto,
      amount: parseFloat(form[cid].amount),
    }));

    if (paymentMethod === PROVIDER_TYPE.PAYCASH || paymentMethod === PROVIDER_TYPE.PUNTORED) {
      return (
        <PaymentReference
          provider={paymentMethod}
          amount={totalAmount}
          orders={orders}
          isMultiple
        />
      );
    }
    if (paymentMethod === PROVIDER_TYPE.BANCOMER) {
      return (
        <BBVA
          orders={orders}
          amount={totalAmount}
          isMultiple
          onClose={() => {
            setStep(1);
          }}
        />
      );
    }
    return <h1>Próximamente</h1>;
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
      return;
    }
    navigate(-1);
  };

  return (
    <Layout
      title={literals.title}
      bottomMenu
      onBack={handleBack}
    >
      <div className='contract-payment__container'>
        <Box padding='sm' className='mb-3'>
          {isOutOfHour ? (
            <PaymentOutOfHour schedule={paymentsSchedule} />
          ) : (
            <>
              <StepsProgress
                className='mb-3 w-100'
                steps={[
                  { label: literals.contracts, value: 0 },
                  { label: literals.paymentMethod2, value: 1 },
                  { label: literals.pay, value: 2 },
                ]}
                current={step}
              />
              {step === 0 && renderStep0()}
              {step === 1 && renderStep1()}
              {step === 2 && renderStep2()}
            </>
          )}
        </Box>
      </div>
    </Layout>
  );
};

export default MultiplePayment;
