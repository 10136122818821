import Debugger from 'utils/debugger';
import { loadingActions } from 'modules/loading';
import CarsQuoterApi from './repository';

export async function getVehiclesYears() {
  try {
    const response = await CarsQuoterApi.getVehiclesYears();
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export async function getVehiclesBrands(year) {
  try {
    const response = await CarsQuoterApi.getVehiclesBrands(year);
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export async function getVehiclesModels(year, brand) {
  try {
    const response = await CarsQuoterApi.getVehiclesModels(year, brand);
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export async function getVehiclesVersions(year, brand, model) {
  try {
    const response = await CarsQuoterApi.getVehiclesVersions(year, brand, model);
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export async function getProducts(officeId) {
  try {
    const response = await CarsQuoterApi.getProducts(officeId);
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export const getVehicleSimulate = async (form, amount, term) => {
  try {
    let loanAmount = amount;
    let loanAmountValuation = amount;
    if (!amount) {
      const vehicleValuation = await CarsQuoterApi.getVehiclesValuation(form.year, form.brand, form.model, form.version, form.product, form.productObject?.TipoProducto, form.productObject?.ClaseProducto, form.productObject?.Plazo);
      loanAmount = vehicleValuation?.obj_data?.PrestamoIncremento || vehicleValuation?.obj_data?.Prestamo;
      loanAmountValuation = vehicleValuation?.obj_data?.Prestamo;
    }
    let simulation = null;
    if (loanAmount) {
      simulation = await CarsQuoterApi.getVehicleSimulate(
        loanAmountValuation,
        form.productObject?.PlazoMaximo || term,
        form.productObject?.Version,
        form.product,
        form?.office?.id,
      );
      return {
        vehicleValuation: loanAmount,
        simulation,
      };
    }
    return false;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
};

export const getVehiclesPrices = (form) => async (dispatch) => {
  try {
    dispatch(loadingActions.startLoading());
    const response = await CarsQuoterApi.getVehiclesPrices(form.brand, form.model, form.version, form.year);
    dispatch(loadingActions.stopLoading());
    return response;
  } catch (error) {
    Debugger.error(error);
    dispatch(loadingActions.stopLoading());
    return false;
  }
};
