import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

const CmsLink = ({ to, children, external: propExternal }) => {
  const external = useMemo(() => (
    propExternal || ([null, undefined].includes(propExternal) && to?.startsWith('http'))
  ), [propExternal, to]);

  const handleClick = (e) => {
    if (external) {
      e.preventDefault();
      window.openExternalLink(to);
    }
  };

  return (
    <Link to={to} onClick={handleClick}>
      {children}
    </Link>
  );
};

CmsLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  external: PropTypes.bool,
};

CmsLink.defaultProps = {
  external: null,
};

export default CmsLink;
