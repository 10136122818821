import Translate from 'components/Translate';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import useLiterals from 'utils/hooks/useLiterals';

const InlineError = ({ error, margin, className }) => {
  const literals = useLiterals('errors');

  const message = useMemo(() => {
    if (typeof error === 'string') {
      return literals[error] || error;
    }
    if (typeof error === 'boolean') {
      return literals.default;
    }
    if (error?.data?.message) {
      return literals[error?.data?.message] || error?.data?.message;
    }
    if (typeof error?.data?.error === 'object') {
      return Object.keys(error?.data?.error).map((key) => {
        const value = error.data.error[key];
        if (typeof value === 'string') {
          return `${key}: ${error.data.error[key]}`;
        }
        if (Array.isArray(value)) {
          return value.map((v) => `${key}: ${v?.message || literals.unknown}`).join('\n');
        }
        return `${key}: ${literals.unknown}`;
      }).join('\n');
    }
    if (literals[error?.statusCode]) {
      return literals[error?.statusCode];
    }

    return literals.unknown;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Translate className={`text-white bg-danger text-center p-3 mt-${margin} mb-${margin} rounded ${className}`} literal={message} />
  );
};

InlineError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]).isRequired,
  margin: PropTypes.string,
  className: PropTypes.string,
};

InlineError.defaultProps = {
  className: '',
  margin: '4',
};

export default InlineError;
