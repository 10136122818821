const INITIAL_STATE = {
  session: {
    user: {},
    authenticated: false,
    checked: false,
    error: '',
  },
  loading: {
    count: 0,
  },
  stores: {
    list: [],
    coords: {
      latitude: null,
      longitude: null,
    },
    store: null,
    loaded: false,
    error: '',
  },
  contracts: {
    list: [],
    error: '',
    needUpdate: false,
    loaded: false,
  },
  sections: {
    list: [],
    error: '',
    needUpdate: false,
    loaded: false,
  },
  configuration: {
    list: [],
    error: '',
    loaded: false,
    loading: false,
  },
  cms: {
    pages: {
      data: {},
    },
    popups: {
      error: '',
      loaded: false,
      loading: false,
    },
  },
};

export default INITIAL_STATE;
