import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import useLiterals from 'utils/hooks/useLiterals';
import Button from 'components/UI/Button';
import { ReactComponent as PaycashLogo } from 'assets/logos/paycash.svg';
import { ReactComponent as PuntoredLogo } from 'assets/logos/logo-puntored.svg';
import WalmartLogoImage from 'assets/logos/walmart.svg';
import SorianaLogoImage from 'assets/logos/soriana.svg';
import BodegaAurreraLogoImage from 'assets/logos/bodegaaurrera.svg';
import SamsLogoImage from 'assets/logos/samsclub.svg';
import CircleKLogoImage from 'assets/logos/circlek.svg';
import { formatDate } from 'utils/formatDate';
import { formatCurrency } from 'utils/formatCurrency';
import {
  createMultipleReference as createMultiplePaycashReference,
  createReference as createPaycashReference,
  deleteReference as deletePaycashReference,
} from 'modules/payCash/actions';
import {
  createMultipleReference as createMultiplePuntoredReference,
  createReference as createPuntoredReference,
  deleteReference as deletePuntoredReference,
} from 'modules/puntored/actions';
import SuccessModal from 'components/FeedbackModals/SuccessModal';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import Modal from 'components/Modal';
import { ROUTE_PATH } from 'routes';
import { getContracts } from 'modules/contracts/actions';
import { getSections } from 'modules/sections/actions';
import {
  CONTRACT,
  CONTRACT_STATUS,
  SECTION,
  PROVIDER_TYPE,
} from 'constants/global';
import { translate } from 'utils/translate';
import useConfiguration from 'hooks/useConfiguration';
import Translate from 'components/Translate';
import PartnersImagePuntored from 'assets/images/puntored-partners.jpg';
import './styles.scss';

const PaymentReference = ({
  id,
  type,
  payment,
  orders,
  isMultiple,
  amount,
  onClose,
  onDelete,
  onDownloadSectionNote,
  provider,
}) => {
  const literals = useLiterals('contractPay');
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [reference, setReference] = useState({});
  const [loading, setLoading] = useState(false);
  const [showDeleteReferenceModal, setShowDeleteReferenceModal] = useState(false);
  const { getConfigurationValue } = useConfiguration();
  const paycashReminderText = getConfigurationValue('paycashReminderText');
  const dispatch = useDispatch();
  const isPuntored = provider === PROVIDER_TYPE.PUNTORED;

  const handleCreateReference = () => {
    const onSuccess = (response) => {
      setLoading(false);
      if (response) {
        setReference(response);
        setShowSuccessModal(true);
      } else {
        setShowErrorModal(true);
      }
    };
    const onError = () => {
      setLoading(false);
      setShowErrorModal(true);
    };

    setLoading(true);
    if (isMultiple) {
      const createMultipleReference = isPuntored
        ? createMultiplePuntoredReference
        : createMultiplePaycashReference;

      createMultipleReference(amount, orders, provider)
        .then(onSuccess)
        .catch(onError);
    } else {
      const createReference = isPuntored
        ? createPuntoredReference
        : createPaycashReference;

      createReference(
        payment?.paymentKey,
        payment?.amount,
        amount || payment?.amount,
        type,
        id,
        provider,
      ).then(onSuccess)
        .catch(onError);
    }
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    dispatch(type === SECTION ? getSections() : getContracts());
    if (isMultiple) {
      navigate(ROUTE_PATH.PAYMENTS);
    } else if (type === CONTRACT) {
      navigate(ROUTE_PATH.setContract(id));
    } else {
      navigate(ROUTE_PATH.setSection(id));
    }
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
    onClose();
  };

  const handleDeleteReference = () => {
    setLoading(true);
    const deleteReference = isPuntored
      ? deletePuntoredReference
      : deletePaycashReference;

    deleteReference(payment?.reference, provider)
      .then(() => {
        setLoading(false);
        setShowDeleteReferenceModal(false);
        dispatch(type === SECTION ? getSections() : getContracts());
        onDelete();
        onClose();
      })
      .catch(() => {
        setLoading(false);
        setShowErrorModal(true);
      });
  };

  return (
    <div className='payment-reference-resume'>
      {showDeleteReferenceModal && (
        <Modal
          title={literals.reference.deleteReference}
        >
          <div className='text-center p-3'>
            <div className='text-gray mb-4'>{literals.reference.deleteReferenceDescription}</div>
            <div className='d-flex gap-2 justify-content-between'>
              <Button
                onClick={() => setShowDeleteReferenceModal(false)}
                type='secondary'
                text={literals.common.cancel}
              />
              <Button
                onClick={handleDeleteReference}
                type='primary'
                text={literals.common.accept}
                loading={loading}
              />
            </div>
          </div>
        </Modal>
      )}
      {showSuccessModal && (
        <SuccessModal
          title={
            isMultiple
              ? literals.reference.successMultipleTitle
              : translate(literals.reference[type === SECTION ? 'successSectionTitle' : 'successContractTitle'], { id })
          }
          onCloseNavigateRoute={ROUTE_PATH.HOME}
          onClose={handleCloseSuccessModal}
        >
          <div className='text-center p-3'>
            <div className='text-gray mb-3'>{literals.reference.successDescription}</div>
            <div className='text-primary font-bold mb-1'>{literals.reference.reference}</div>
            <div className='text-gray mb-2'>{(isMultiple ? reference[0] : reference)?.reference}</div>
            {isMultiple ? (
              <>
                <div className='text-primary font-bold mb-1'>{literals.reference.numberOfContracts}</div>
                <div className='text-gray mb-2'>{reference.length}</div>
              </>
            ) : (
              <>
                <div className='text-primary font-bold mb-1'>{literals.reference[type === CONTRACT ? 'contractNumber' : 'sectionNumber']}</div>
                <div className='text-gray mb-2'>{id}</div>
              </>
            )}
            <div className='text-primary font-bold mb-1'>{literals.reference.issueDate}</div>
            <div className='text-gray mb-2'>{formatDate((isMultiple ? reference[0] : reference)?.createdAt, 'DD/MM/YYYY')}</div>
            <div className='text-primary font-bold mb-1'>{literals.reference.amount}</div>
            <div className='text-gray mb-2'>{formatCurrency(amount)}</div>
          </div>
        </SuccessModal>
      )}
      {showErrorModal && (
        <ErrorModal
          title={literals.reference.failureTitle}
          description={literals.reference.failureDescription}
          onClose={handleCloseErrorModal}
        />
      )}
      <h1 className='mb-4 text-primary'>{literals.paymetResume}</h1>
      {isMultiple ? (
        <div>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{literals.contracts}</h2>
            <h3 className='text-gray'>{orders.length}</h3>
          </div>
        </div>
      ) : (
        <>
          <div className='d-flex justify-content-between mb-2'>
            <h2 className='font-bold'>{type === CONTRACT ? literals.contract : literals.section}</h2>
            <h3 className='text-gray'>{id}</h3>
          </div>
          {payment?.reference && (
            <div className='d-flex justify-content-between mb-2 gap-4 reference-container'>
              <h2 className='font-bold'>{literals.reference.reference}</h2>
              <h3 className='text-gray'>{payment.reference}</h3>
            </div>
          )}
          {payment?.limitDate && (
            <div className='d-flex justify-content-between mb-2'>
              <h2 className='font-bold'>{literals.date}</h2>
              <h3 className='text-gray'>{payment?.limitDate}</h3>
            </div>
          )}
          {payment?.expirationDate && (
            <div className='d-flex justify-content-between mb-2'>
              <h2 className='font-bold'>{literals.date}</h2>
              <h3 className='text-gray'>{formatDate(payment?.expirationDate, 'DD/MM/YYYY')}</h3>
            </div>
          )}
        </>
      )}
      {payment?.reference && payment?.status ? (
        <div className='d-flex justify-content-between mb-2'>
          <h2 className='font-bold'>{literals.status}</h2>
          <h3 className='text-gray font-bold'>{literals.statusValues[payment?.status] || payment?.status}</h3>
        </div>
      ) : null}
      {payment?.authorizationNumber ? (
        <div className='d-flex justify-content-between mb-2'>
          <h2 className='font-bold'>{literals.paymetAuthorization}</h2>
          <h3 className='text-gray font-bold'>{payment?.authorizationNumber}</h3>
        </div>
      ) : null}
      <div className='d-flex justify-content-between mb-2'>
        <h2 className='font-bold'>{literals.paymentMethod2}</h2>
        {isPuntored ? (
          <PuntoredLogo width={120} height={28} />
        ) : (
          <PaycashLogo width={120} height={28} />
        )}
      </div>
      <div className='separator my-3' />
      <div className='d-flex justify-content-between mb-2'>
        <h2 className='font-bold'>{literals.totalAmount}</h2>
        <h3 className='text-primary font-bold'>{formatCurrency(amount || payment?.amount)}</h3>
      </div>
      {!payment?.reference ? (
        <Button
          onClick={handleCreateReference}
          className='mt-4'
          type='primary'
          loading={loading}
          text={literals.reference.generatePaymentOrder}
        />
      ) : null}
      {(type === SECTION && payment?.reference && payment?.status === CONTRACT_STATUS.COMPLETED) ? (
        <Button
          onClick={onDownloadSectionNote}
          className='mt-4'
          type='primary'
          text={literals.reference.downloadNote}
        />
      ) : null}
      {(payment?.reference && payment?.status === CONTRACT_STATUS.PENDING) ? (
        <Button
          onClick={() => setShowDeleteReferenceModal(true)}
          className='mt-4'
          type='primary'
          text={literals.reference.deleteReference}
          loading={loading}
        />
      ) : null}
      {payment?.status !== CONTRACT_STATUS.COMPLETED && paycashReminderText && (
        <div className='text-start mt-4'>
          <Translate literal={paycashReminderText} />
        </div>
      )}
      <div className='mt-4'>
        <p>{literals.reference.shops}</p>
        {isPuntored ? (
          <img className='w-100' src={PartnersImagePuntored} alt='partners' />
        ) : (
          <ul className='paycash-partners-list'>
            <li>
              <p>{literals.reference.walmart}</p>
              <img src={WalmartLogoImage} alt='Walmart Logo' width={120} height={28} />
            </li>
            <li>
              <p>{literals.reference.soriana}</p>
              <img src={SorianaLogoImage} alt='Soriana Logo' width={120} height={28} />
            </li>
            <li>
              <p>{literals.reference.bodegaAurrera}</p>
              <img src={BodegaAurreraLogoImage} alt='Bodega Aurrera Logo' width={120} height={28} />
            </li>
            <li>
              <p>{literals.reference.sams}</p>
              <img src={SamsLogoImage} alt='Sams Logo' width={120} height={28} />
            </li>
            <li>
              <p>{literals.reference.circleK}</p>
              <img src={CircleKLogoImage} alt='CircleK Logo' width={120} height={28} />
            </li>
            <li>
              <p>{literals.reference.mas}</p>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

PaymentReference.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  payment: PropTypes.object,
  orders: PropTypes.array,
  amount: PropTypes.number.isRequired,
  isMultiple: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onDownloadSectionNote: PropTypes.func,
  provider: PropTypes.string,
};

PaymentReference.defaultProps = {
  id: '',
  type: '',
  payment: null,
  orders: [],
  isMultiple: false,
  onClose: () => {},
  onDelete: () => {},
  onDownloadSectionNote: () => {},
  provider: '',
};

export default PaymentReference;
