export const rgbToHex = (rgb) => {
  if (!rgb) return '';
  // If already in hex format, return as is
  if (rgb?.startsWith('#')) return rgb;
  // Extract RGB values
  const matches = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (!matches) return '';

  // Convert each component to hex
  const r = parseInt(matches[1], 10).toString(16).padStart(2, '0');
  const g = parseInt(matches[2], 10).toString(16).padStart(2, '0');
  const b = parseInt(matches[3], 10).toString(16).padStart(2, '0');
  return `#${r}${g}${b}`;
};
