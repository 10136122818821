export const formatLink = (link) => {
  const data = {
    to: '',
    target: link?.startsWith('http') ? '_blank' : null,
  };
  if (link) {
    if (link?.startsWith('http') || link?.startsWith('/')) {
      data.to = link;
    } else {
      data.to = `/${link}`;
    }
  }
  return data;
};
