/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';
import { CMS_POPUP_TYPE } from 'constants/cms';
import { preparePath } from 'utils/preparePath';

const ApiFetchInstance = ApiFetch(window.CMS_API_URL);

export const URLS = {
  page: 'cms/pages/url',
  popups: 'cms/popups',
  documents: 'cms/documents/public',
};

export default {
  getPage(path) {
    return ApiFetchInstance.get(`${URLS.page}?url=${path}`);
  },
  getPopups() {
    return ApiFetchInstance.get(preparePath(URLS.popups, null, { type: CMS_POPUP_TYPE.IN_APP, enabled: true, size: 1000 }));
  },
  getDocument(hash) {
    return ApiFetchInstance.get(`${URLS.documents}/${hash}`);
  },
};
