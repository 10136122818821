/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import initialState from 'store/initialState';

export const sectionsSlice = createSlice({
  name: 'sections',
  initialState: initialState.sections,
  reducers: {
    init: (state) => {
      state.list = [];
      state.error = '';
      state.loaded = false;
    },
    error: (state, action) => {
      state.list = [];
      state.error = action.payload;
      state.loaded = false;
    },
    clearError: (state) => {
      state.error = '';
      state.loaded = false;
    },
    success: (state, action) => {
      state.list = action.payload;
      state.error = '';
      state.loaded = true;
      state.needUpdate = false;
    },
    needUpdate: (state) => {
      state.needUpdate = true;
    },
    clear: (state) => {
      state.list = [];
      state.error = '';
      state.loaded = false;
      state.needUpdate = false;
    },
  },
});

export const sectionsActions = sectionsSlice.actions;

export default sectionsSlice.reducer;
