import { useEffect, useRef, useState } from 'react';
import Layout from 'components/Layout';
import { getGestopagoUrl } from 'modules/session/actions';
import { useDispatch } from 'react-redux';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'routes';
import useLiterals from 'utils/hooks/useLiterals';
import { genericCommand } from 'utils/events';

const PortalGestopago = () => {
  const literals = useLiterals('gestopago');
  const [url, setUrl] = useState('');
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const iframeRef = useRef(null);

  useEffect(() => {
    dispatch(getGestopagoUrl()).then((res) => {
      if (res) {
        setUrl(res.replace('gestopago.portalventas.net', 'montepio.portalventas.net'));
      } else {
        setError(true);
      }
    });
    const handleMessage = (event) => {
      const eventData = typeof (event?.data) === 'string' ? JSON.parse(event?.data) : event?.data || {};
      if (eventData?.type) {
        genericCommand(eventData.type, eventData?.payload || {}).then((res) => {
          if (res && iframeRef.current) {
            iframeRef.current.contentWindow.postMessage(JSON.stringify(res), '*');
          }
        });
      }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      className='gestopago-portal'
      headerMenu={!!url}
      bottomMenu={!url}
      rightMenu={false}
      onBack={() => navigate(ROUTE_PATH.HOME, { replace: true })}
    >
      <iframe
        ref={iframeRef}
        className='embed-page with-menu'
        src={url}
        title='Gestopago'
        allow='camera; microphone'
      />
      {error && (
        <ErrorModal
          description={literals.error}
          onClose={() => navigate(ROUTE_PATH.HOME)}
        />
      )}
    </Layout>
  );
};

export default PortalGestopago;
