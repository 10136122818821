import { CONTRACT, SECTION } from 'constants/global';
import { getContracts } from 'modules/contracts/actions';
import { getSections } from 'modules/sections/actions';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';

export const useContractOrSection = (path, id) => {
  const dispatch = useDispatch();
  const contracts = useSelector((state) => state.contracts);
  const sections = useSelector((state) => state.sections);

  const type = useMemo(() => (matchPath({ path }, window.location.pathname) ? CONTRACT : SECTION), [path]);
  const contract = useMemo(() => (type === CONTRACT && contracts.list.find((c) => c.Numero === id)) || null, [contracts, id, type]);
  const section = useMemo(() => (type === SECTION && sections.list.find((c) => c.id === +id)) || null, [sections, id, type]);

  useEffect(() => {
    if (type === CONTRACT && (!contracts.loaded || contracts.needUpdate)) {
      dispatch(getContracts());
    }
    if (type === SECTION && (!sections.loaded || sections.needUpdate)) {
      dispatch(getSections());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return { type, contract, section };
};
