/* eslint-disable no-unsafe-optional-chaining */
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CONTRACT, SECTION } from 'constants/global';
import PropTypes from 'prop-types';
import useLiterals from 'utils/hooks/useLiterals';
import scheduleIcon from 'assets/icons/schedule.svg';
import Button from 'components/UI/Button';
import Box from 'components/UI/Box/Box';
import { ROUTE_PATH } from 'routes';
import { formatDate } from 'utils/formatDate';
import { formatCurrency } from 'utils/formatCurrency';
import documentIcon from 'assets/icons/document.svg';
import watchIcon from 'assets/icons/watch.svg';
import ringIcon from 'assets/icons/ring.svg';
import carIcon from 'assets/icons/car.svg';
import cellPhoneIcon from 'assets/icons/cellphone.svg';
import ProgressChart from './ProgressChart';
import './styles.scss';

export const renderIconCategories = (type, isAuto = false, src = false) => {
  let imgSrc = '';
  if (isAuto) {
    imgSrc = carIcon;
  } else {
    switch (Number(type)) {
      case 1:
        imgSrc = ringIcon;
        break;
      case 2:
        imgSrc = watchIcon;
        break;
      case 3:
        imgSrc = cellPhoneIcon;
        break;
      default:
        imgSrc = scheduleIcon;
    }
  }
  return (
    src ? imgSrc : <img src={imgSrc} alt='product-icon' className='product-icon' />
  );
};

const PaymentBox = ({
  type,
  data,
  action,
  showAction,
  inBox,
  actionText,
  actionDisabled,
}) => {
  const literals = useLiterals('contract');
  const navigate = useNavigate();

  const info = useMemo(() => {
    if (!data) {
      return null;
    }
    if (type === CONTRACT) {
      /* Auto contracts have incorrect fields, and Alexa requested this. */
      return {
        id: data?.Numero || '-',
        icon: renderIconCategories(data?.Ramo, data?.isAuto, true),
        description: `${literals.status}: ${data?.DesEstatus || '-'}`,
        paid: data.isAuto ? data?.CapitalRestante : (data?.MontoContratacion - data?.CapitalRestante) || 0,
        pending: (data.isAuto ? (data?.MontoContratacion - data?.CapitalRestante) : data?.CapitalRestante) || 0,
        capital: data?.MontoContratacion || 0,
        expirationDate: formatDate(data?.FechaVencimiento, 'DD/MM/YYYY'),
        isAuto: !!data?.isAuto,
      };
    }
    if (type === SECTION) {
      return {
        id: data?.id || '-',
        icon: documentIcon,
        description: data?.description.map((desc) => desc.description).join(', ') || '-',
        paid: data?.deposits || 0,
        pending: data?.remainingCapital || 0,
        capital: data?.capital || 0,
        expirationDate: data?.expirationDate || '-',
      };
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, type]);

  const handleAction = () => {
    if (action) {
      action(info.id);
    } else if (type === CONTRACT) {
      navigate(ROUTE_PATH.setContract(info.id));
    } else {
      navigate(ROUTE_PATH.setSection(info.id));
    }
  };

  const renderHeader = () => {
    const title = type === CONTRACT ? literals.contract : literals.section;
    if (!inBox) {
      return (
        <div className='deadlines-box-header mb-3'>
          <div className='deadlines-box-header-left'>
            <h2>{title}</h2>
            <h2>{info.id}</h2>
          </div>
        </div>
      );
    }
    return (
      <div className='deadlines-box-header'>
        <div className='deadlines-box-header-left'>
          <div className='deadlines-box-header-left-header mb-3'>
            <img src={info.icon} alt='product-icon' className='product-icon' />
            <h3 className='mb-0'>
              {`${title} ${info.id}`}
            </h3>
          </div>
          {info.description && (
            <ul>
              <li>
                <h4>{info.description}</h4>
              </li>
            </ul>
          )}
        </div>
      </div>
    );
  };

  const renderProgress = () => {
    return (
      <div className='deadlines-box-progress mb-3'>
        <div className={`deadlines-box-progress-header mb-2 ${info.paid > 0 ? '' : 'without-pending'}`}>
          <div>
            <p className='text-gray mb-1'>{literals.returned}</p>
            <h4>{formatCurrency(info.paid)}</h4>
          </div>
          {info.paid > 0 && !info.isAuto ? (
            <div>
              <p className='text-gray mb-1'>{literals.pending}</p>
              <h4>{formatCurrency(info.pending)}</h4>
            </div>
          ) : null}
          <div>
            <p className='text-gray mb-1'>{literals.total}</p>
            <h4>{formatCurrency(info.capital)}</h4>
          </div>
        </div>
        <div className='deadlines-box-progress-chart'>
          <ProgressChart total={info.capital} pending={info.pending} />
        </div>
      </div>
    );
  };

  const renderDeadline = () => {
    return (
      <div className='deadlines-box-deadline'>
        <div className='deadlines-box-deadline-left'>
          <p className='text-gray'>{literals.nextExpiration}</p>
          <p>{info.expirationDate}</p>
        </div>
        {showAction && (
          <div className='deadlines-box-deadline-right'>
            <Button
              size='sm'
              onClick={() => handleAction()}
              text={actionText || literals.amortize}
              disabled={actionDisabled}
            />
          </div>
        )}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className='deadlines-box mb-3'>
        {renderHeader()}
        {renderProgress()}
        {renderDeadline()}
      </div>
    );
  };

  if (!data) {
    return null;
  }

  if (!inBox) {
    return renderBody();
  }

  return (
    <Box className='deadlines-box mb-3' padding='sm'>
      {renderBody()}
    </Box>
  );
};

PaymentBox.displayName = 'PaymentBox';

PaymentBox.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  actionText: PropTypes.string,
  actionDisabled: PropTypes.bool,
  showAction: PropTypes.bool,
  inBox: PropTypes.bool,
  action: PropTypes.func,
};

PaymentBox.defaultProps = {
  type: CONTRACT,
  data: null,
  action: null,
  showAction: true,
  inBox: true,
  actionText: '',
  actionDisabled: false,
};

export default PaymentBox;
